import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Diciembre"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/diciembre"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/diciembre",
        title: "Efemérides - Diciembre | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – DICIEMBRE</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de diciembre de 1786: Nació en Salta Juan Antonio Fernández, hijo de
      Juan Antonio Fernández, también salteño y de doña Amelia González Hoyos,
      hermana de la esposa del general Juan Antonio Alverez de Arenales. Estudió
      sus primeras letras y latinidad en Salta con el educador español José L.
      Cabezón. Luego pasó al Seminario de San Cristóbal en La Plata, donde
      estudió filosofía y teología, luego en la Universidad Mayor de San Marcos
      de Lima, en la cual graduóse de San Fernando de Lima, terminando sus
      estudios y recibiendo su título de Bachiller en Medicina en el año 1811.
      Viajó a Europa, a España, para perfeccionar sus estudios. Regresó como
      médico en la expedición enviada al Río de la Plata al mando del General
      Vigodet. En Montevideo comenzó su carrera como médico. Entre 1814 y 1815
      como cirujano del ejército. Fue designado secretario del Instituto Médico
      Militar y Catedrático de Instituciones Médicas. Perfecto del departamento
      de Medicina en la Universidad de Buenos Aires fundada el 9 de agosto de
      1821. En 1822 al crearse la Academía de Medicina de Buenos Aires forma
      parte del grupo de los 15 miembros primeros de la flamante Corporación.
      Rivadavia lo destinó Catedrático de Patología y clínica médica, juntamente
      con el cargo de facultativo del Hospital de Mujeres. Asumió Rosas la
      gobernación de Buenos Aires y Fernández e radicó en Montevideo durante 17
      años donde ejerció la profesión y atendió epidemias y acciones guerreras.
      A la caída de Rosas regresó a Buenos Aires. Retomó sus tareas en la
      Universidad de Buenos Aires como profesor de Clínica Médica primero y más
      tarde como presidente de la Facultad de Medicina. Trabajó hasta fines de
      1854. Falleció el 24 de setiembre de 1855.
    </p>
    <p>
      1 de diciembre de 1843&nbsp;: Nació Guillermo Aráoz en Salta. En 1869
      realizó el primer censo poblacional. Realizó investigaciones y
      expediciones topográficas. Falleció el 7 de abril de 1914.
    </p>
    <p>
      1 de diciembre de 1910: Se creó la Caja de Jubilaciones y Pensiones de la
      Provincia de Salta.
    </p>
    <p>
      1 de diciembre de 1939&nbsp;: Nació en Los Blancos, departamento de
      Rivadavia, provincia de Salta, Fauto Ponciano Machuca. Maestro normal
      casado con María Amanda Frías. En octubre de 1983 resultó electo senador
      por Rivadavia, cargo para el que fue reelecto en 1987 y en 1991. Integró
      las Comisiones de Justicia, Peticiones y Acuerdo y Educación. Miembro de
      la Comisión del Mérito Artístico. Creó y presidió la Comisión de Derechos
      Humanos. Presidente de varios períodos de la Comisión Bicameral
      Examinadora de Obras de Autores Salteños. Autor de la Ley Nº 6.373 de
      Promoción y Desarrollo de Comunidades Aborígenes, que dió lugar a la
      creación del Instituto Provincial del Aborígen. En la actualidad es
      secretario de Derechos Humanos de la Provincia de Salta.
    </p>
    <p>
      1 de diciembre de 1941: Falleció el gobernador de la Provincia de Salta,
      Doctor Abraham Cornejo. Lo sucedió en el cargo el vicegobernador Ernesto
      M. Aráoz.
    </p>
    <p>
      1 de diciembre de 1980&nbsp;: Canal 11 de Salta, inauguró la repetidora
      para Metán y Rosario de la Frontera.
    </p>
    <p>
      1 de diciembre de 1995&nbsp;: Falleció Francisco Miguel “Paco” Cenice, a
      los 63 años. Fue director del Intransigente. En el momento de su muerte
      realizaba tareas periodísticas en Radio Nacional Salta y en Aries,
      Frecuencia Modulada.
    </p>
    <p>
      2 de diciembre de 1831&nbsp;: Se firmó en Salta un tratado limítrofe entre
      Salta y el general Juan Facundo Quiroga.
    </p>
    <p>
      3 de diciembre de 1929&nbsp;: Se fundó la Asociación Odontológica de
      Salta. Por iniciativa de los doctores Lérida, Outes, José Vicente Solá,
      Ovejero Grande y muchos más.
    </p>
    <p>
      3 de diciembre de 1931: Nació en Salta, el poeta Walter Adet. Publicó en
      libro: “En el sendero gris”, “Antología de la poesía tucumana”, “César
      Vallejo”, “El aire que anochece”, “El memorial de Jonás”, “El escudo de
      Dios”, “La Casa donde soy”, otros. Recopiló en una obra a escritores y
      poetas de Salta, bajo el título de: “Cuatro siglos de literatura Salteña”.
    </p>
    <p>
      3 de diciembre de 1934&nbsp;: Nació en Salta, Juan Carlos Matthews. Médico
      desde 1962 y doctor en Medicina desde 1973. Cardiólogo del Hospital
      Paterson, donde fue su director y el jefe del servicio de Clínica Médica.
      Presidente del Colegio Médico, de la Liga de Fútbol del Ramal Jujeño. Fue
      intendente de San Pedro de Jujuy. Miembro titular de la Sociedad Argentina
      de Cardiología. Reside en San Pedro de Jujuy.
    </p>
    <p>
      3 de diciembre de 1980: Murió el poeta Jaime Dávalos. Reconocido y
      valorado por todo el país. Gran aporte realizó al cancionero popular
      argentino.
    </p>
    <p>
      4 de diciembre de 1933: Nació en Salta, Luis Eugenio Voyer. La primaria la
      realizó en la Escuela J. J. de Urquiza, el secundario en el Colegio
      Nacional de Salta y en 1960 se recibió de médico en la Facultad de
      medicina de Buenos Aires. Miembro de entidades científicas argentinas y
      extranjeras. Autor de publicaciones científicas en Argentina y en el
      extranjero. Becado y premiado por sus estudios. Reside en Capital Federal.
    </p>
    <p>
      4 de diciembre de 1957&nbsp;: Don Guillermo Uriburu Roca y doña Leonor de
      Anchorena, donaron al Museo de Salta, la espada y otros efectos que le
      pertenecieron al Brigadier General don Juan Antonio Alvarez de Arenales.
    </p>
    <p>
      5 de diciembre de 1891: Se creó el departamento de La Candelaria, en la
      provincia de Salta, designándose a la Villa del Tala, como capital
      departamental.
    </p>
    <p>
      5 de diciembre de 1893&nbsp;: Por Decreto Nº 455-198, se creó el Municipio
      de El Tala, posteriormente reafirmado por ley Nº 50/39.
    </p>
    <p>
      5 de diciembre de 1942: Nació en Salta, el poeta Jorge Díaz Bavio. Publicó
      en libro: “El insomnio y las vísperas”, “Palabra por palabra”, “El Beto”,
      “El árbol de navidad” y otras obras. Ejerce el periodismo en radio y
      televisión. Logró importantes premios literarios. Se sumó como autor de
      canciones y logró premios y reconocimientos a nivel nacional.
    </p>
    <p>
      5 de diciembre de 1987&nbsp;: Se creó la Sede Orán del Instituto
      Güemesiano de Salta, que se inauguró el 14 de diciembre. El primer
      presidente fue el Dr. Lucio Martín Rufino.
    </p>
    <p>
      6 de diciembre de 1782&nbsp;: Nació en San Carlos (Salta), Jacinto del
      Carrillo. En 1810 colaboró con el teniente Martín Miguel de Güemes.
      Integró el escuadrón “Decididos de Salta”, creado por el General Belgrano.
      Fue teniente del 2º escuadrón del Valle de Cachi. En 1820 fue promovido
      por el General Güemes, al grado de capitán. Falleció el 16 de agosto de
      1821.
    </p>
    <p>
      6 de diciembre de 1901: Nació el reverendo padre Arsenio Seage (sacerdote
      don Bosco). Estrechamente vinculado a la cultura salteña. Durante el
      arzobispado de monseñor Tavella, fue el ejecutor de los planes culturales
      del arzobispo, especialmente en la creación del Instituto de Humanidades,
      la primera Casa de Estudios Universitarios de Salta. Es autor de una
      biografía de monseñor Tavella.
    </p>
    <p>
      6 de diciembre de 1945&nbsp;: Se hizo cargo del gobierno de la provincia
      de Salta, el Interventor Federal, coronel Angel W. Escalada.
    </p>
    <p>
      7 de diciembre de 1766: Nació en Salta, Francisco de Gurruchaga. Estudió
      en el colegio de Nobles de Madrid, España. Después se graduó en
      jurisprudencia en la Universidad de Granada, España. Cuando Inglaterra
      declaró la guerra a España, se alistó en las filas de la madre patria. Se
      embarcó como oficial ayudante del Capitán de Navío don Baltasar Hidalgo de
      Cisneros, en la gigantesca nave insignia Santísima Trinidad. Luchó en
      Trafalgar, conquistando el grado de teniente de fragata. Al apresar las
      fuerzas de Napoleón al rey de España Fernando VII, abandonó la lucha para
      gestar la emancipación de América junto con José de Moldes y Juan Martín
      de Pueyrredón. Fugaron y embarcaron en Cádiz, llegando a Buenos Aires el 7
      de enero de 1809. Al estallar la revolución de 1810, fue elegido como
      diputado de Salta, ante la junta de Buenos Aires. Esta le encomendó la
      creación de una escuadrilla naval. La armó con propio peculio y la integró
      con las naves Invencible, 25 de mayo y América. La puso al mando de
      Azopardo y sucumbió en San Nicolás de los Arroyos en 1811 (2 de marzo de
      1811). Armó una segunda escuadra con 7 unidades y también con sus
      peculios. Colaboró con Güemes y con Belgrano con dinero, uniformes,
      víveres. Murió octogenario de Salta, el 20 de diciembre de1846, pobre. Su
      sepelio fue costeado por sus amigos y su restos sepultados en la Iglesia
      de San Francisco.
    </p>
    <p>
      7 de diciembre de 1816&nbsp;: El Cabildo de Salta, bajo la presidencia del
      gobernador, juró acatamiento al Acta de la Independencia del 9 de Julio de
      1816.
    </p>
    <p>
      7 de diciembre de 1932: Nació Martín Adolfo Borelli. Agricultor y
      ganadero. Presidente del Centro Nativo Cultural de Cachi El Chiguanco.
      Presidente del Colegio de jueces de Paz de la Provincia. Actualmente juez
      de Paz de Cachi. Como literato logró importantes premios y publicó cinco
      libros. Se destacan. Tiempo y silencio, Poemas y Mirando la tierra.
    </p>
    <p>
      7 de diciembre de 1991&nbsp;: Se fundó la Sede Joaquín V. González, del
      Instituto Güemesiano de Salta. Su primer presidente fue el profesor Jorge
      Pacheco.
    </p>
    <p>
      8 de diciembre de 1792&nbsp;: Falleció Francisco Gabino Arias Rengel y
      Acevedo. Había nacido en Salta el 9 de octubre de 1732. Era hijo del
      general José Arias Rengel y Heredia.
    </p>
    <p>
      8 de diciembre de 1837&nbsp;: Falleció en Córdoba, Juan Andrés Aguirre.
      Nacido en Salta a mediados del siglo XVIII. Abogado, doctorado en
      teología. Recibió el título correlativo de la Real Audiencia de Charcas.
      Integró la Junta Provincial de Gobierno de Buenos Aires en junio de 1811.
    </p>
    <p>
      8 de diciembre de 1841: Murió en La Paz (Bolivia), Eusebio Martínez de
      Mollinedo. Nació en Salta en 1794. Se incorporó a las fuerzas patriotas
      del Ejército del Norte al mando del general Belgrano. Integró las huestes
      defensivas de la Frontera Norte, comandadas por el general Martín Güemes.
      Acompañó a Güemes en la campaña contra Bernabé Aráoz. Asistió al Héroe en
      sus últimos momentos el 17 de junio de 1821, luego continuó a las ordenes
      del general Arenales. Además de militar fue hombre de gran cultura.
    </p>
    <p>
      8 de diciembre de 1843: Nació en Salta, Delfín Leguizamón. A los 21 años
      fue elegido diputado provincial. El 10 de Octubre de 1867 actuó contra
      Felipe Varela junto a un grupo de milicianos reclutados en los valles,
      quienes lo derrotaron poniendo a la fuga al invasor, quien se debió
      refugiar en Chile. Fue gobernador interino desde el 23 de abril hasta el
      13 de junio de 1869. Fue elegido gobernador de la provincia desde el 13 de
      junio de 1871 hasta el 13 de junio de 1873, resultando ser el gobernador
      más joven de la historia de Salta. Nuevamente fue elegido gobernador,
      asumió desde el 1º de mayo de 1893 hasta el 20 de febrero de 1896. Fue
      elegido senador nacional desde 1897 hasta 1903. Falleció el 20 de abril de
      1917.
    </p>
    <p>
      8 de diciembre de 1895: Nació en Salta, Guillermo Juan Velarde Mors,
      conocido popularmente como Pajarito Velarde. Por su casa de Pueyrredón
      106, casi esquina España, pasaron grandes artistas como Los Fronterizos,
      Los Cantores del Alba, Atahualpa Yupanqui, Eduardo Falú. Esa casa se ha
      convertido en Museo, pues guarda recuerdos de una época de gloria de la
      cultura argentina. Hasta el mismo Carlos Gardel ha dejado testimonios en
      ese lugar. Diario El Tribuno de Salta, es celoso guardián de ese MUSEO/.
    </p>
    <p>
      8 de diciembre de 1945&nbsp;: Nació en Orán, provincia de Salta, Ramón
      Emilio Mónico. Ocupó diversos cargos políticos en distintos gobiernos
      justicialistas. Realiza periodismo en temas políticos.
    </p>
    <p>
      9 de diciembre de 1864&nbsp;: Fue creado por decreto el Colegio Nacional
      de Salta, bajo la presidencia del general Bartolomé Mitre.
    </p>
    <p>
      9 de diciembre de 1927&nbsp;: Falleció de una afección al corazón Luis
      Güemes, nieto del héroe, quien nació en Salta el 6 de febrero de 1816. Era
      hijo del hacendado Luis Güemes Puch y de doña Rosaura Castro y Sanzetenea.
    </p>
    <p>
      9 de diciembre de 1947&nbsp;: Nació en Salta, Néstor Roberto Bixquert. Es
      contador Público Nacional y en la actualidad es Vicepresidente de la
      Cámara de Comercio e Industria de Salta.
    </p>
    <p>
      9 de diciembre de 1963&nbsp;: Canal 11 de Salta, logró la concesión de
      línea para iniciar su transmisión.
    </p>
    <p>
      10 de diciembre de 1942&nbsp;: Fue declarada, Monumento Histórico por
      decreto Nº 137.845, la Iglesia de San Carlos, provincia de Salta. En 1792
      fue enterrado allí su primer cura vicario, don Francisco de Arambúru y en
      1831 doña Josefa de Frías, a quien expresa el libro de defunciones no se
      le exigió ningún derecho para dar cumplimiento a tan piadoso acto, por
      haber donado dicha dama los terrenos en que se asentó la iglesia.
    </p>
    <p>
      10 de diciembre de 1942&nbsp;: Fue declarada Monumento Histórico por
      decreto Nº 137.845, la Iglesia de Molinos. Allí se encuentra la momia del
      general Nicolás Severo de Isasmendi Echalar, fallecido en Molinos en 1837.
    </p>
    <p>
      10 de diciembre de 1945&nbsp;: Fue declarada, Monumento Histórico por
      Decreto Nº 30.839, la iglesia de Cachi. Su antigüedad se remonta a
      mediados del siglo XVIII, y tuvo su origen en los terrenos de la hacienda
      de Cachi, de don Felipe J. de Aramburu. En esta iglesia se lleva libros
      desde 1792. A 147 km. de Salta.
    </p>
    <p>
      10 de diciembre de 1945&nbsp;: Fueron declaradas Monumento Históricos por
      decreto Nº 30.833, las Ruinas de Incahuasi. Están en el departamento de
      Rosario de Lerma, 2860 metros sobre el nivel del mar en Potreros de
      Uriburu, entre las Quebradas de Incahuasi y del Toro, junto al arroyo
      Incamayo. Los investigadores expresan que se trata de los restos de una
      antigua fortaleza incásica, que servía para mantener los dominios sobre
      las tribus diaguitas, atacamas, humahuacas, chiriguanos o lules y que fue
      construida en tiempos del Inca Yupanqui o de Huaina Capac en el siglo XV.
    </p>
    <p>
      10 de diciembre de 1983 :&nbsp;Asumió como gobernador de Salta, don
      Roberto Romero. Fue martillero, periodista, empresario, estadista,
      político. El 10 de octubre de 1986, se firmó en su gestión y en Salta, la
      declaración de Integración del Norte Grande Argentino, constituido por las
      provincias de Catamarca, Chaco, Corrientes, Formosa, Jujuy, Misiones,
      Salta, Santiago del Estero y Tucumán. Doce años después asume su hijo Juan
      Carlos, la gobernación de Salta.&nbsp;
      <br />
      10 de diciembre de 1987 : Asumió como gobernador de Salta, el contador
      Público Nacional, Hernán Hipólito Cornejo.
    </p>
    <p>
      10 de diciembre de 1991&nbsp;: Asumió como gobernador de Salta, el capitán
      de Navío (RE), Roberto Augusto Ulloa, quien fue reemplazado por el doctor
      Juan Carlos Romero, el 10 de diciembre de diciembre de 1995.
    </p>
    <p>
      11 de diciembre de 1787&nbsp;: Nació en Salta, María Magdalena Dámasa
      Güemes, conocida como Macacha. Apoyo y guía de su hermano. Otros
      historiadores afirman que nació el 12 de diciembre y que falleció el 7 de
      junio de 1866.
    </p>
    <p>
      11 de diciembre de 1862&nbsp;: La representación general de la provincia,
      presidida por don Segundo Díaz de Bedoya, sancionó la ley de fundación de
      colonia Rivadavia, en la provincia de Salta.
    </p>
    <p>
      11 de diciembre de 1960&nbsp;: Nació en el departamento de Anta, provincia
      de Salta, Miguel Angel Torino. Abogado que en 1991 resultó electo Senador
      por el departamento de Anta, cargo para el que fue reelecto el 1 de
      octubre de 1995. Fue presidente del bloque de Senadores del partido
      Justicialista y presidente de la Comisión de Hacienda y Presupuesto. El
      gobernador de Salta, doctor Juan Carlos Romero, lo designó Ministro de
      Gobierno, cargo que ocupa en la actualidad.
    </p>
    <p>
      12 de diciembre de 1854&nbsp;: Nació en Salta, Pedro José Frías. Sobrino
      del teniente general Eustoquio Frías, quien lo hizo ingresar a la facultad
      de medicina de BuenosAires donde se recibió de médico. Fue diputado
      provincial, ministro de gobierno y gobernador de Salta desde el 3 de julio
      de 1890 hasta el 1º de mayo de 1893. También fue senador nacional. Murió
      en Salta el 13 de enero de 1909.
    </p>
    <p>
      12 de diciembre de 1945&nbsp;: Nació en Salta, Arturo Eduardo Aguilar
      Michel. Abogado. Fue ministro de Gobierno y de Economía en el gobierno de
      Hernán Hipólito Cornejo.
    </p>
    <p>
      12 de diciembre de 1965&nbsp;: Se fundó en Metán, provincia de Salta, la
      Asociación de Básquetbol del Sur.
    </p>
    <p>
      12 de diciembre de 1980&nbsp;: Se fundó en la ciudad de Salta, el
      Sanatorio Parque.
    </p>
    <p>
      12 de diciembre de 1988&nbsp;: En esta jornada culminó la exposición que
      realizó Emilio Haro Galli, desde el 29 de noviembre en el Museo de Arte
      Popular Tiwanaku, de Caseros 476 en la ciudad de Salta y que dirige Carlos
      Nadal.&nbsp;
      <br />
      <br />
      13 de diciembre de 1767&nbsp;: El gobernador Fernández Campero, de regreso
      a Salta procedente de Jujuy, se encontró en La Caldera con su teniente de
      gobernador, Francisco de Toledo y Primentel, quien luego de un
      enfrentamiento armado tomó prisionero al gobernador Fernández Campero y lo
      envió a la Audiencia de Charcas.
    </p>
    <p>
      13 de diciembre de 1834&nbsp;: El general Pablo Latorre, gobernador de
      Salta, que había reconocido la independencia de Jujuy (18 de noviembre de
      1834), separándose de la primera. Luego lo desconoce por lo que el
      gobernador de Jujuy, coronel José María Fascio, marcha contra su enemigo y
      lo venció haciéndolo prisionero, el que en compañía del coronel José María
      Aguilar fueron asesinados en su prisión el 29 de diciembre de 1834,
      ocurrido en Castañares o Pozo Verde, en la provincia de Salta.
    </p>
    <p>
      13 de diciembre de 1930&nbsp;: Se inauguró el ferrocarril que cubría el
      tramo: Embarcación-Yacuiba.
    </p>
    <p>
      13 de diciembre de 1994&nbsp;: Se inauguró la Sede, Zona Centro de la
      Asociacón Salteña de Bochas, bajo la gestión de los dirigentes Carlos
      Alberto Hinojosa y Nicolás Alberto Medina. Esta sede está ubicada en el
      Pasaje Hernández entre Zuviría y Mitre.
    </p>
    <p>
      14 de diciembre de 1929&nbsp;: Nació en Tucumán, maría Elvira C. de
      Martos. Se dedicó a la docencia. Publicó Súplica, en 1974. Figura en una
      antología de Orán.
    </p>
    <p>
      14 de diciembre de 1988&nbsp;: Se llevó a cabo la clausura de los actos
      programados, en la Sede Orán del Instituto Güemesiano al cumplir el primer
      aniversario.
    </p>
    <p>
      15 de diciembre de 1816&nbsp;: Gauchos del ejército de Güemes, derrotaron
      a los realistas mandados por el coronel Juan Lóriga, en Quebrada del Toro,
      provincia de Salta.
    </p>
    <p>
      15 de diciembre de 1817&nbsp;: El coronel Manuel Eduardo Arias, del
      ejército de Güemes, derrotó a los realistas mandado por el brigadier José
      Antonio de Olañeta, en Caluti, provincia de Salta.
    </p>
    <p>
      15 de diciembre de 1834&nbsp;: Fue elegido gobernador de Salta, José
      Antonio Fernández Cornejo, ocupando el cargo por tercera vez, pero será
      depuesto por sus tendencias unitarias.
    </p>
    <p>
      15 de diciembre de 1891&nbsp;: Nació en la ciudad de Salta don Miguel Solá
      en un hogar pleno de tradición. Era descendiente directo de quien fue, a
      mediados del siglo XVIII, gobernador del Tucumán, primero, y presidente de
      la Audiencia de Charcas, luego: don Juan Victorino Martínez de Tineo. Fue
      uno de los salteños que descollaron. En su juventud residió en Salta,
      donde llegó a ser director del diario “Nueva Epoca”. Más tarde se radicó
      en Buenos Aires, donde realizó periodismo y estudios y publicación de
      trabajos sobre historia del arte. Trabajó 30 años en el diario La Prensa y
      colaboró en la revista “Caras y Caretas”. Entre sus libros: “Historia del
      arte precolombino”, (publicados en 1935 y 1936). “Arquitectura colonial de
      Salta”, “Salta”, Miguel Otero”, Memoria de Güemes a Rosas” (1946); “Las
      milicias de Güemes (1963); Diccionario Biográfico de Salta (1964). Fue uno
      de los fundadores del Instituto Güemesiano de Salta. Murió en Buenos Aires
      el 6 de octubre de 1979, próximo a cumplir 88 años.
    </p>
    <p>
      15 de diciembre de 1985&nbsp;: Se inició la obra para construir el
      Polideportivo Ciudad de Salta, DELMI, a cargo de la empresa Astori de
      Córdoba, que ganó la licitación. Se construyó en 160 días. Los integrantes
      del comité organizador pro estadio ciudad de Salta (COPECS) trabajaron ad
      honoren para concretar esta empresa. El presidente del COPECS era el señor
      Tomás Ubaldino Mena, quien era acompañado por reconocidos profesionales y
      empresarios de nuestro medio. El empuje del gobernador de la provincia de
      la provincia don Roberto Romero, el COPECS y el apoyo de la comunidad a
      través de sus instituciones deportivas y empresarias que aportaron
      recursos han hecho posible esta realidad.
    </p>
    <p>
      16 de diciembre de 1820 :&nbsp;Fue electo gobernador de Salta por el
      Cabildo, el doctor José Ignacio Gorriti. Este cargo fue delegado por
      Güemes, ya que él trabajaba por los ideales en pro de la emancipación
      americana, en coordinación con el accionar de San Martín.
    </p>
    <p>
      16 de diciembre de 1930&nbsp;: Nació en Tartagal, Salta, Canyo Isaac.
      Compuso algunos trabajos literarios. Incluído en Antología de Orán.
    </p>
    <p>
      17 de diciembre de 1829&nbsp;: Nació en Salta, Pedro Antonio Pardo.
      Médico, periodista, diputado provincial, diputado nacional ante el
      Congreso de Paraná, Ministro de Relaciones Exteriores y Culto, Decano de
      la Facultad de Ciencias Médicas. Falleció en Lisboa el 5 de febrero de
      1889.
    </p>
    <p>
      17 de diciembre de 1930&nbsp;: Falleció el doctor Bernardo Frías, quien
      fue el que enalteció la figura de Güemes. Fue autor de la “Historia del
      general Martín Güemes y de la Provincia de Salta o sea de la Independencia
      Argentina. Se lo llama el Descubridor de Güemes.
    </p>
    <p>
      17 de diciembre de 1960&nbsp;: Nació en Salta, Adriana del Valle Pérez.
      Ingeniera química que incursiona en política. Fue Concejal de la
      Municipalidad de la Ciudad de Salta y en la actualidad es la Secretaria de
      Cultura de la provincia.
    </p>
    <p>
      18 de diciembre de 1850&nbsp;: Nació en Salta, don Virgilio Mariano Tedín.
      Se graduó de abogado en 1869. Se radicó en la misma ciudad de Buenos
      Aires, donde estudió. Realizó una brillante carrera en los estamentos de
      justicia. Su desempeño en la justicia mereció la consideración de la
      ciudadanía. Falleció en la Capital Federal en junio de 1893.
    </p>
    <p>
      18 de diciembre de 1882&nbsp;: Nació en Filadelfia (E.E.U.U.), Ricardo
      Fontaine Maury. Llegó a nuestro país en 1906. Fue el Huaytiquina o Ramal
      C-14, trabajo que realizó con 1300 obreros y numerosos sacrificados
      profesionales, desde el año 1921, hasta 1948. Luego de 27 años de trabajo,
      se inauguró oficialmente la obra, el 20 de febrero de 1948. Tiene 571 kms,
      1400 curvas, 3233 metros de 2 túneles, 31 puentes de acero con 670 metros.
      Es una de las obras ferroviarias más espectaculares del mundo. Fueron
      declarada por el Senado de la Nación Reversa Histórica y Monumento
      Nacional. Maury falleció en Córdoba el 31 de julio de 1950. Sus restos
      descansan al lado de la vía, en Campo Quijano.
    </p>
    <p>
      18 de diciembre de 1897&nbsp;: Nació en Salta, José Vicente Solá. En
      Buenos Aires, se recibió de odontólogo. Fue presidente de la Cámara de
      Diputados. Importantes colaboraciones para diarios y revistas del País.
      (La Nación – La Prensa – El Hogar, etc.). Dedicado al estudio de la
      lengua, publicó: Gramática Castellana, Diccionario de la Regionalismos de
      Salta, Contribución del árabe a la formación de la lengua castellana.
      Curiosidades gramaticales. Murió en Salta, el 23 de Noviembre de 1961.
    </p>
    <p>
      19 de diciembre de 1790&nbsp;: Asumió el gobierno de Salta, el coronel de
      infanteria Ramón García de León y Pizarro, quien gobernó hasta 1796, al
      término del cual Güemes tenía 11 años de edad.
    </p>
    <p>
      19 de diciembre de 1953&nbsp;: Nació en Cuzco, Perú, Katia Gibaja de
      Gallegos. Licenciada en Psicología. Docente en IEM de la Unsa. Profesora
      de Jardín de Infantes. Se desempeña en el departamento de Psicología y
      Asistencia Escolar del Consejo General de Educación. Profesora de Quechua.
      Difusora de cultura Indo-Americana y conductora del programa Ecos de la
      patria Grande por Radio Nacional Salta. Fundadora de las Jornadas
      Pre-Colombinas que se realizan anualmente en la ciudad de Salta.
    </p>
    <p>
      19 de diciembre de 1989&nbsp;: Falleció la dirigente salteña Ana María
      Giacosa. Fue directora del MUSEO/ Arias Rengel. Fue la referente más
      importante de Salta, del Partido Político que lideraba Abelardo Ramos a
      nivel nacional.
    </p>
    <p>
      19 de diciembre de 1994&nbsp;: Asumió como rector de la Universidad
      Nacional de Salta, el Contador Público Nacional, Narciso Ramón Gallo.
    </p>
    <p>
      20 de diciembre de 1810&nbsp;: Falleció el pintor y escultor Tomás
      Cabrera. Se afirma que nació en Salta 1740. Estudió en Chile artes
      plásticas. Hizo trabajos en la zona de Cuyo y en otros templos de San
      Juan. Sarmiento lo menciona en su libro: “Recuerdos de Provincia”, en el
      que lo denomina “Miguel Angel americano” y lo menciona como pintor
      salteño. Le pertenece la primera obra pictórica de carácter histórico. En
      el Museo Histórico Nacional de Buenos Aires; se conserva el cuadro
      denominado: Entrevista del Gobernador don Gerónimo de Matorras con el
      Cacique Paykin en el Chaco”. En el Cabildo de Salta, se conserva la
      valiosa talla de la “Virgen de la Candelaria”. La escuela de Bellas Artes
      de Salta lleva su nombre.
    </p>
    <p>
      20 de diciembre de 1814&nbsp;: Nació en Salta, Jacoba Tomasa Saravia. Hija
      de José Tomasa Saravia y de José Apolinario Saravia. Estudió las primeras
      letras con su tío el doctor Facundo de Zuviría. Luego ingresó al colegio
      fundado por el general Gorriti, siendo compañera de Juana Manuela Gorriti.
      Completó sus estudios en el Colegio de Educandos (hoy Colegio de Jesús).
      Ejerció la docencia con dedicación. Falleció en Salta, el 24 de junio de
      1891.
    </p>
    <p>
      20 de diciembre de 1839&nbsp;: Falleció Antonio Castellanos Saravia,
      nacido en Salta en 1782. Graduóse de médico en 1810. Participó en las
      luchas contra las invasiones inglesas. Permaneció en las filas patriotas
      siendo al grado de cirujano mayor del ejército auxiliar. En 1821 fue
      enviado por el jefe realista para que ofreciera sus servicios
      profesionales al general Güemes que se encontraba herido gravemente en la
      Quebrada de la Orqueta. Pero el héroe gaucho rechazó su ofrecimiento.
      Impresionado por este hecho abandona la política, pues pertenecía al
      partido “Patria Nueva” adversario de Güemes, para prescindir de todo
      partidismo. Prestó colaboraión a Gorriti cuando éste ejerció el gobierno
      de la provincia, propiciando la vacunación y otras medidas sanitarias.
    </p>
    <p>
      20 de diciembre de 1846&nbsp;: Murió en Salta, Francisco de Gurruchaga,
      ciudad que lo vió nacer en 1766. Falleció en la mayor pobreza, este hombre
      que era hijo de padres con títulos mobiliarios y de gran fortuna. Sus
      restos fueron sepultados en la iglesia San Francisco, en el altar de la
      Virgen del Rosario, siendo costeados los gastos del sepelio por un grupo
      de amigos. Sus padres eran Pedro Antonio de Gurruchaga y Alzaga de origen
      vasco y doña Manuela Fernández Pedrozo y Aguirre, salteña de linaje
      entroncado con los conquistadores de Chile y Cuyo.
    </p>
    <p>
      20 de diciembre de 1948&nbsp;: Nació en Salta, Sergio Gareca. Destacado
      periodista que desempeña sus actividades en diario El Tribuno.
    </p>
    <p>
      21 de diciembre de 1871&nbsp;: Nació en Anquincila, Ancasti, Catamarca, el
      doctor Tomás Amalio Vergara. Estudió primero en Catamarca. En Buenos Aires
      se graduó de médico cirujano en 1899. Fue el creador y director de la
      Dirección Nacional Antiplalúdica. Ministro de Gobierno, diputado
      provincial, (presidente del cuerpo), diputado nacional, senador
      provincial. Murió el 10 de abril de 1935.
    </p>
    <p>
      21 de diciembre de 1876&nbsp;: Renunció a la gobernación Miguel F. Aráoz,
      quien había asumido el 13 de junio de 1875. Desempeñó interinamente la
      gobernación don Benedicto Fresco, en su carácter de Presidente del Senado.
    </p>
    <p>
      21 de diciembre de 1923&nbsp;: Falleció Joaquín V. González. Político,
      Jurista y escritor argentino. Nació en La Rioja en 1863. Fundó la
      Universidad de la Plata en 1905. Su libro más conocido; Mis Montañas. Un
      pueblo de Salta lleva su nombre.
    </p>
    <p>
      21 de diciembre de 1938&nbsp;: Nació en la ciudad de Mendoza, Hugo Alberto
      Orellano. Maestro Normal Nacional. Locutor Nacional que se desempeñó en
      distintas emiroras mendocinas y en la actualidad es el conductor del
      programa Hola Salta que se emitió en diferentes emisoras salteñas y hoy en
      su propia radio, FM Provincia.
    </p>
    <p>
      21 de diciembre de 1946&nbsp;: Fray Francisco de la Cruz Muguerza fue
      ordenado sacerdote. Con el tiempo sería el primer Obispo de la Diócesis de
      San Ramón de la Nueva Orán, en la provincia de Salta.
    </p>
    <p>
      22 de diciembre de 1971&nbsp;: Falleció el sacerdote José Mir, quien
      residió en Metán desde 1940. La biblioteca municipal de metán lleva su
      nombre.
    </p>
    <p>
      22 de diciembre de 1984&nbsp;: Murió César Fermín Perdiguero, el cochero
      joven. Activo trabajador de la cultura. Realizó periodismo escrito y
      radial. Gran amimador de festivales y creador de fiestas para Salta. Su
      último libro publicado en vida: Antología del Cerro San Bernardo, está
      lleno de sabrosas historias de nuestra provincia de Salta. Cuando joven
      formó un dúo con Eduardo Falú.
    </p>
    <p>
      23 de diciembre de 1918&nbsp;: Falleció el doctor Manuel Gorostiaga a los
      70 años. Este ilustre santiagueño fue periodista, orador, político. Fue
      diputado al Congreso de la Nación (1884-1888). Entre los años 1911-1913
      actuó como presidente de kla comisión de límites de Santiago del Estero,
      en cuestiones suscitadas con Tucumán, Salta, Córdoba y Catamarca. Fue
      diputado provincial. Fue fundador y director del diario El País de
      propiedadde José E. Gorostiaga.
    </p>
    <p>
      23 de diciembre de 1955&nbsp;: Nació en Buenos Aires, Margarita Liliana
      Saborida. Ingeniera y profesora de Artes Visuales. Docente de la Escuela
      de Bellas Artes Tomás Cabrera y de la Escuela de Música de la provincia,
      “José Lo Giúdice”. Desarrolla actividad técnica en la Casa de la Cultura
      de Salta. Fue jurado de concursos docentes, fotográficos y de diseño. Es
      integrante de la Orquesta Estable de la provincia, que dirige el maestro
      Antonio Montero, como violoncello de fila.
    </p>
    <p>
      23 de diciembre de 1988&nbsp;: Fue el último día de remates de obras de
      arte de artistas plásticos residentes en Salta. Lo organizó Ayllu, una
      Sociedad Civil sin fines de lucro, con el objeto de realizar un centro
      Cultural Independiente. Se realizó en la galería de arte “Gloria Alemán” y
      fue auspiciada por el Banco Provincial de Salta.
    </p>
    <p>
      24 de diciembre de 1826&nbsp;: Fue aprobada por el Congreso General
      Constituyente, la Constitución de la República Argentina, bajo la
      presidencia de Bernardino Rivadavia. Fue sancionada por 72 diputados de 17
      provincias. Destacamos algunas de las provincias y sus
      representantes:&nbsp;
      <br />
      CATAMARCA : Inocencio González Espeche, Miguel Díaz de la Peña, Nicolás de
      Avellaneda y Tula y José Antonio Barros.&nbsp;
      <br />
      LA RIOJA : Santiago Vázquez y Eugenio Gregorio Ruso.&nbsp;
      <br />
      SALTA Y JUJUY : Juan Ignacio Gorriti, Francisco Remigio Castellanos, José
      Arenales, Alejandro Heredia, José Miguel Zegada y Manuel de Tezanos
      Pinto.&nbsp;
      <br />
      SANTIAGO DEL ESTERO : Félix Ignacio Frías, Vicente Mena, Manuel Dorrego,
      Antonio María Taboada, José Francisco Ugarteche y Juan Antonio
      Meirot.&nbsp;
      <br />
      TUCUMAN : José Ignacio Garmendia, Gerónimo Helguera y Juan Bautista
      Paz.&nbsp;
      <br />
      MONTEVIDEO : Manuel Moreno, Mateo Vidal, Silvestre Blanco y Cayetano
      Campana.&nbsp;
      <br />
      TARIJA : José Felipe Echazú.
    </p>
    <p>
      25 de diciembre de 1817&nbsp;: Nació en San Ramón de la Nueva Orán
      (Salta), José Manuel Arias Cornejo. Doctor en jurisprudencia. Secretario
      de la Sala de Representantes de Jujuy. Convencional para la Reforma
      Constitucional de Salta en 1855. Ministro general del gobernador José
      María Todd en 1856. Diputado nacional de Salta. Senador de la Nación por
      Salta, propulsó la creación de la Biblioteca Pública.
    </p>
    <p>
      25 de diciembre de 1872&nbsp;: Siendo titular de la diócesis de Salta,
      monseñor Buenaventura Rizo Patrón y Zabala, se realizó la solemne
      bendición del Templo de la Parroquia de San José de Metán.
    </p>
    <p>
      25 de diciembre de 1953&nbsp;: Nació en Campo Quijano, Salta, Jesús
      Armando Guaimás. Desde joven fue dirigente juvenil en su pueblo natal,
      actuando en cooperadoras, clubes y en política. Fue secretario de gobierno
      de su municipio de 1983 a 1987. Intendente de 1987 a 1991 y de 1991 a
      1995. En 1993 fue elegido diputado, cargo al que renunció para continuar
      al frente del municipio.
    </p>
    <p>
      26 de diciembre de 1817 :&nbsp;El coronel Manuel Eduardo Arias, del
      ejército de Güemes, derrotó completamente al brigadier Pedro Antonio de
      Olañeta al frente del ejército realista, en San Lucas, provincia de Salta.
    </p>
    <p>
      26 de diciembre de 1830&nbsp;: Las tropas del gobernador de Salta, juan
      José Gorriti son derrotadas por el coronel Román Deheza, en Salta.
    </p>
    <p>
      26 de diciembre de 1962&nbsp;: Nació en Salta, Diego Haro. Poeta de las
      últimas generaciones. Publicó en libro: “Arenas ardientes” con una
      limitada edición artesanal realizada a mano.
    </p>
    <p>
      27 de diciembre de 1777&nbsp;: Asumió el gobierno de Salta, Martín de
      Mestre, quien permaneció en el poder hasta el 1782.
    </p>
    <p>
      27 de diciembre de 1909&nbsp;: Se creó el Municipio de General Güemes por
      Decreto Nº 376/1909, luego reafirmado por ley Nº 1185 del 2 de agosto de
      1947.
    </p>
    <p>
      27 de diciembre de 1932&nbsp;: Nació en Buenos Aires Febo Mazzaglia.
      Radicado en Salta desde su juventud. 40 años de militancia en el partido
      justicialista, este hombre que ocupó una banca en la Cámara de Diputados
      de la provincia de Salta, donde fue presidente de la Comisión de Juicio
      Político. Fue presidente de la Comisión de Presupuesto y Hacienda, de la
      Honorable Cámara de Diputados de la Provincia de Salta. Ocupó importantes
      cargo en Salta, en Jujuy y a nivel nacional.
    </p>
    <p>
      28 de diciembre de 1932&nbsp;: Se creó la Dirección de Vialidad de Salta.
    </p>
    <p>
      29 de diciembre de 1834&nbsp;: Fue ultimado en su lecho por el coronel
      Mariano Santibañez obedeciendo órdenes del coronel Fascio, don Pablo
      Latorre, quien fuera gobernador interino, luego del derrocamiento del
      general José Antonino Fernández Cornejo desde el 22 de setiembre hasta el
      31 de diciembre de 1834, en que fue derrotado y herido por las fuerzas
      jujeñas al mando del coronel Fascio, quien ordenó asesinarlo. La sentencia
      cumplió en el Cabildo de Salta, donde estaba detenido.
    </p>
    <p>
      29 de diciembre de 1915&nbsp;: Se creó por Decreto Nº 620/1915, el
      Municipio de Embarcación.
    </p>
    <p>
      31 de diciembre de 1889&nbsp;: Falleció el Doctor Adolfo Martínez,
      gobernador de Salta, siendo reemplazado interinamente por el doctor Sixto
      Ovejero.
    </p>
    <p>
      31 de diciembre de 1914&nbsp;: Se aprobó y reglamentó por Ordenanza el
      Proyecto de don Atilio Lanzi, por el que se creó el Hospital de metán, que
      luego se llamara Del Carmen.
    </p>
    <p>
      31 de diciembre de 1930&nbsp;: Nació en Rosario de la Frontera, provincia
      de Salta Tomás Ubaldino Mena. Fue diputado provincial y Presidente del
      COPECS, entidad que hizo construir el DELMI, Estadio Polideportivo Salta
      en 160 días. Destacado periodista de diario El Tribuno, autor de la
      columna “A la hora del cierre”, a la que firma con el seudónimo de
      Tombolito. Gran benefector de escuelas, de niños y familias carenciadas.
      Habitante permanente de los clubes de Salta.
    </p>
    <p>
      31 de diciembre de 1933&nbsp;: Monseñor Julio Campero convocó a su grey a
      la realización de un Congreso Eucarístico Diocesano en Salta, entre el 25
      y el 31 de mayo de 1934.
    </p>
    <p>
      31 de diciembre de 1935 :&nbsp;El Tranvía salteño realizó su último viaje
      por las calles de Salta. Este servicio nació el 23 de mayo de 1913 y le
      fue encomendado a la Compañía Anglo-Argentina de Electricidad S.A. por 99
      años. Luego la empresa pasó a llamarse la Compañía de Electricidad del
      Norte Argentino S.A. El último tramo fue retirado en 1978 de San Martín
      esquina Ituzaingó.
    </p>
    <p>
      31 de diciembre de 1995&nbsp;: Falleció en accidente automovilístico la
      señora Antonia Tránsito Gaamundi de Clinis, quien había sido diutada
      provincial, por el departamento San Martín, de la provincia de Salta.
      Residía en Embarcación.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
